module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="footer">\r\n        <div class="container w1250">\r\n            <div class="footer-logo">\r\n                <img src="' +
((__t = (require('./images/footer-logo.png'))) == null ? '' : __t) +
'" alt="">\r\n            </div>\r\n            <div class="footer-nav">\r\n                <ul>\r\n                    <h1>产品应用</h1>\r\n                    <li><a href="">纤维</a></li>\r\n                    <li><a href="">UD布</a></li>\r\n                    <li><a href="">劳保产品</a></li>\r\n                    <li><a href="">防弹防刺产品</a></li>\r\n                </ul>\r\n                <ul>\r\n                    <h1>关于我们</h1>\r\n                    <li><a href="">公司简介</a></li>\r\n                    <li><a href="">生产情况</a></li>\r\n                    <li><a href="">质量控制</a></li>\r\n                    <li><a href="">检测报告</a></li>\r\n                </ul>\r\n                <ul class="last-ul">\r\n                    <h1>联系我们</h1>\r\n                    <li>电话：15646654654545</li>\r\n                    <li>传真：464565663636363</li>\r\n                    <li>电子邮件：2315156551515@qq.com</li>\n                    <li>\n                      <div class="icon">\n                        <a href="javascript:;">\n                          <div class="iconfont icon-weixin"></div>\n                        </a>\n                        <div class="ewm">\n                          <img src="' +
((__t = (require('./images/ewm.jpg'))) == null ? '' : __t) +
'" alt="">\n                        </div>\n                      </div>\n                      <div class="icon">\n                        <a href="javascript:;">\n                          <div class="iconfont icon-weibo"></div>\n                        </a>\n                        <div class="ewm">\n                          <img src="' +
((__t = (require('./images/ewm.jpg'))) == null ? '' : __t) +
'" alt="">\n                        </div>\n                      </div>\n                      <div class="icon">\n                        <a href="javascript:;">\n                          <div class="iconfont icon-douyin"></div>\n                        </a>\n                        <div class="ewm">\n                          <img src="' +
((__t = (require('./images/ewm.jpg'))) == null ? '' : __t) +
'" alt="">\n                        </div>\n                      </div>\n                    </li>\r\n                </ul>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="copyright">Copyright 2021 Sinty Sci-Tech Co., Ltd. All rights reserved.</div>\r\n</footer>\n';

}
return __p
}