import './index.scss'
// 打开搜索
$('.nav ul li .search-icon').on('click', function() {
  if ($(this).hasClass('search-show')) {
    $(this).removeClass('search-show')
    $('.header-search').removeClass('show')
  } else {
    $(this).addClass('search-show')
    $('.header-search').addClass('show')
  }
})
// 点击打开移动端导航栏
$('.mob-menu-icon').on('click', function() {
  $('.mob-nav').addClass('show')
  $('.mob-nav-mask').fadeIn(200)
})
$('.mob-nav .close-mob-nav').on('click', function() {
  $('.mob-nav').removeClass('show')
  $('.mob-nav-mask').fadeOut(200)
})
// 滚动监听
// $(document).scroll(function() {
//   var scroH = $(document).scrollTop()
//   if (scroH > 100) {
//     $('.header').addClass('scroll-header')
//   } else {
//     $('.header').removeClass('scroll-header')
//   }
// });
// 手机端点击展开子级
$('.mob-nav .menu ul li>a').on('click',function () {
    let El = $(this).parents('li')
    $(El).hasClass('active')
    if ($(El).hasClass('active')) {
        $(El).removeClass('active').find('.subnav-mob').slideUp();
    }else{
        $(El).addClass('active').siblings().removeClass('active')
        $(El).find('.subnav-mob').slideDown();
        $(El).siblings().find('.subnav-mob').slideUp();
    }
})
